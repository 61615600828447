import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout.js";

const PoetryPage = () => {
  return (
    <Layout
      title="lars montanaro | poetry"
      image={
        <StaticImage
          alt="Lars Montanaro, in a ghost costume"
          src="../images/header_poetry.jpg"
        />
      }
    ></Layout>
  );
};

export const Head = () => <title>Lars Montanaro</title>;

export default PoetryPage;
